<template>
  <div v-if="getVisibleMarathon">
    <div style="padding: 0 2px; ; position: relative; z-index: 24; ">
      <div class="notification-popup-content notification-popup-content-marathon">
        <div class="close" @click="closePopup('XButtonClose')" />
        <div class="goPlayWin" @click="playWinRoute()">
          <img :src="getMarathonImage" @load="onImgLoad" alt="notification icon" />
          <div v-if="isLoaded" class="marathon-popup-point">{{ getMarathonPoint }}&nbsp;puan</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'Marathon',
  mixins: [gtmUtils],
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleMarathon() {
      return this.getPopups.popupId == 2 && this.$route.fullPath == '/secure/feed';
    },
    getMarathonImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
    getMarathonPoint() {
      return this.getPopups?.popupDetail?.point ?? '';
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      return (this.isLoaded = true);
    },
    playWinRoute() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('odül_yagmuru', {
        location: 'pop_up',
      });
      let playWinRoute = `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}/${RoutesPlayAndWin.PlayAndWinDetail.path}`;
      this.closePopup('LinkClick');
      this.$router.push(playWinRoute);
    },
    closePopup(action) {
      const eventData = {
        event: 'cEvent',
        category: 'Pop Up',
        action: 'Close',
        label: 'Marathon',
        value: 0,
        isNonInteraction: false,
      };
      this.$emit('closePopup', eventData, { type: action });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popup {
  position: absolute;
  right: -10px;
  left: -10px;
  bottom: -12px;
  background-color: #fff;
  z-index: -1;
  border-radius: 5px;
  cursor: pointer;
  &-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 323px;
    .text {
      position: absolute;
      left: 20px;
      &.top {
        bottom: 75px;
        color: #ffbfbf;
      }
      &.bottom {
        bottom: 42px;
        color: #fff;
        font-size: 24px;
      }
    }
    &-marathon {
      .text {
        left: 16px;
        &.top {
          bottom: 50px;
          color: #23303d;
        }
        &.bottom {
          bottom: 20px;
          color: #fc9507;
          font-size: 24px;
        }
      }
    }
    .close {
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 15px;
        width: 16px;
        height: 2px;
        background-color: #fff;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(135deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .goPlayWin {
      cursor: pointer;
    }
  }
}
.marathon-popup-point {
  position: absolute;
  top: 118px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: bold;
  font-size: 35px;
  text-align: right;
}
</style>
